import { useState } from "react"
import { toast } from "react-toastify"
import useTranslation from "src/hooks/useTranslation"
import { model } from "../../constants/models"
import { signType } from "../../constants/signTypes"
import { treeItemColumn } from "../../constants/treeItemColumn"
import { getUpdatedStatuses } from "../../helpers/getUpdatedStatuses"
import { signModel } from "../../services/general.service"
import useDispatch from "../../store"
import * as treeActions from "../../store/tree/actions"
import Button from "../Button"

export default function SigningButtons({
  modelId,
  worksheetKey,
  statuses,
  indexes,
  type = "big",
  disableConfig = {
    isDisabled: false,
    message: "No puede realizar esta acción"
  },
  onComplete,
  beforeReview = null,
  typeTree = "auditPlan",
  onSuccess = () => {},
  buttons = { completado: true, revisado: true }
}) {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const onSignHandler = async (signingtype) => {
    try {
      if (disableConfig.isDisabled) {
        return toast.error(disableConfig.message)
      }
      setLoading(true)

      switch (signingtype) {
        case signType.REVIEWED: {
          if (beforeReview) {
            setLoading(false)
            const res = await beforeReview()
            if (res === false) {
              return
            }
            setLoading(true)
          }
          break
        }
        case signType.COMPLETE:
          if (onComplete) {
            setLoading(false)
            return onComplete()
          }
          break
      }

      const {
        data: {
          _model_status,
          created_by: { sigla, name },
          firmas
        }
      } = await signModel({
        model:
          typeTree === "auditPlan"
            ? model.TREE_ITEM_AUDIT
            : typeTree === "hallazgo_evaluacion"
            ? "hallazgo_evaluacion"
            : model.TREE_ITEM_RISK_EVALUATION,
        modelId,
        signType: signingtype
      })

      if (typeTree === "hallazgo_evaluacion") {
        const completed = firmas.completado.length > 0
        const reviewed = firmas.revisado.length > 0
        dispatch(
          treeActions.updateTreeRowAndWorksheetData({
            indexes,
            worksheetKey,
            overwrite: true,
            dataToUpdate: {
              status_evaluacion: _model_status,
              evaluacion_firmas: firmas,
              signature: {
                completed,
                reviewed,
                completers: firmas.completado,
                reviewers: firmas.revisado
              },
              statuses: getUpdatedStatuses({
                statusesToUpdate: {
                  EVALUATION: {
                    sigla,
                    status_evaluacion: _model_status,
                    name
                  },
                  [treeItemColumn.SIGNATURE]: {
                    signing: firmas
                  }
                },
                statuses
              })
            }
          })
        )
      } else {
        const completed = firmas.completado.length > 0
        const reviewed = firmas.revisado.length > 0
        dispatch(
          treeActions.updateTreeRowAndWorksheetData({
            indexes,
            worksheetKey,
            overwrite: true,
            dataToUpdate: {
              status: _model_status,
              signature: {
                completed,
                reviewed,
                completers: firmas.completado,
                reviewers: firmas.revisado
              },
              statuses: getUpdatedStatuses({
                statusesToUpdate: {
                  [treeItemColumn.STATUS]: {
                    sigla,
                    status: _model_status,
                    name
                  },
                  [treeItemColumn.SIGNATURE]: {
                    signing: firmas
                  }
                },
                statuses
              })
            }
          })
        )
      }

      setLoading(false)
      toast.success(t("frontend.auditoria.treeitem.success_tree_sheet_status", { action: signingtype }))

      onSuccess()
    } catch (error) {
      setLoading(false)
      return Error(error)
    }
  }

  if (typeTree === "auditTemplate") {
    return null
  }

  return (
    <>
      {buttons?.completado && (
        <Button
          text='main_ui.general.btn_completado'
          icon='done'
          name='green'
          loading={loading ? "lb_loading" : false}
          type={type}
          option='normal'
          disabled={disableConfig?.isDisabled ?? false}
          handleClick={onSignHandler.bind(this, signType.COMPLETE)}
          style={type == "big" ? { height: 32, width: 140, justifyContent: "center" } : {}}
        />
      )}
      {buttons?.revisado && (
        <Button
          loading={loading ? "lb_loading" : false}
          text='main_ui.general.btn_revisado'
          icon='done_all'
          name='normal'
          type={type}
          option='normal'
          disabled={disableConfig?.isDisabled ?? false}
          handleClick={onSignHandler.bind(this, signType.REVIEWED)}
          style={type == "big" ? { height: 32, width: 140, justifyContent: "center" } : {}}
        />
      )}
    </>
  )
}
