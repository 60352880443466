import ErrorBoundary from "src/errors/ErrorBoundary"
import { strippedString } from "../SpecialBox"
import Tooltip, { tooltipPosition } from "../Tooltip"

export default function Truncate({
  children,
  atLine,
  style,
  inline = false,
  content = null,
  innerHtml = true,
  removeBr = false,
  position = tooltipPosition.below,
  maxWidth = null
}) {
  const getStyles = () => {
    let styles = {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      display: inline ? "inline-block" : "block",
      maxWidth
    }
    if (atLine) {
      styles = {
        display: "-webkit-box",
        WebkitLineClamp: `${atLine}`,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        maxWidth
      }
    }

    return styles
  }
  const containerStyle = {}
  if (inline) {
    containerStyle.display = "inline-block"
  }

  return (
    <Tooltip
      content={strippedString(content === null ? children : content)}
      style={style}
      containerStyle={containerStyle}
      position={position}
      useHTML={innerHtml}
    >
      {innerHtml ? (
        <ErrorBoundary>
          <div style={getStyles()} dangerouslySetInnerHTML={{ __html: children }}></div>
        </ErrorBoundary>
      ) : (
        <div style={getStyles()}>{strippedString(children, removeBr)}</div>
      )}
    </Tooltip>
  )
}
