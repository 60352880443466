import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getResponsible = authValidationMiddleware(async () => {
  const response = await axiosApiInstance.get("/general/usuario_responsable/")

  return response.data
})

export const transferResponsible = authValidationMiddleware(async ({ id, data }) => {
  const response = await axiosApiInstance.post(`/general/usuario_responsable/${id}/transfer_planes/`, { ...data })

  return response.data
})

export const createResponsible = authValidationMiddleware(
  async ({ nombre, correo, cargo, area, modelo, modelo_id }) => {
    const response = await axiosApiInstance.post("/general/usuario_responsable/", {
      nombre,
      correo,
      cargo,
      area,
      modelo,
      modelo_id
    })

    return response.data
  }
)

export const updateResponsible = authValidationMiddleware(async ({ responsibleId, payload }) => {
  const response = await axiosApiInstance.put(`/general/usuario_responsable/${responsibleId}/`, {
    ...payload
  })

  return response.data
})

export const deleteResponsible = authValidationMiddleware(async (responsibleId) => {
  const response = await axiosApiInstance.delete(`/general/usuario_responsable/${responsibleId}`)
  return response.data
})

export const signModel = authValidationMiddleware(async ({ model, modelId, signType }) => {
  const response = await axiosApiInstance.post("/general/modelo_firma/", {
    modelo: model,
    modelo_id: modelId.replace("-EV", ""),
    firma_tipo: signType
  })

  return response.data
})

export const associateResponsible = authValidationMiddleware(
  async ({ model, modelId, responsibleIdArray, role = "default" }) => {
    const response = await axiosApiInstance.post("/general/modelo_responsable/", {
      modelo: model,
      modelo_id: modelId.replace(/-EV$|-PA$/, ""),
      responsable: responsibleIdArray,
      rol: role
    })

    return response.data
  }
)

export const serviceRestore = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.post(`/recyclebin/${id}/restore/`)

  return response.data
})

export const uploadExcelService = authValidationMiddleware(async (blobData, fileName, params = {}) => {
  const formData = new FormData()
  formData.append("file", blobData, fileName)

  const response = await axiosApiInstance.post("/general/import/excel/", formData, {
    headers: { "content-type": "multipart/form-data" },
    params
  })

  return response.data
})

export const changeSheetExcelService = authValidationMiddleware(async (txId, sheet) => {
  const response = await axiosApiInstance.post(`/general/import/excel/${txId}/change-sheet/`, { sheet })

  return response.data
})

export const processUploadExcelService = authValidationMiddleware(async (txId, payload) => {
  const response = await axiosApiInstance.post(`/general/import/excel/${txId}/process/`, payload)

  return response.data
})

export const fetchTransactionService = authValidationMiddleware(async (txId) => {
  const response = await axiosApiInstance.get(`/general/transaction/${txId}/`)

  return response.data
})
