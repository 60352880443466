import { CKEditor } from "ckeditor4-react"
import cx from "classnames"
import debounce from "lodash/debounce"
import merge from "lodash/merge"
import { useRouter } from "next/router"
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { batch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import EditorAttachment from "src/components/Editor/EditorAttachment"
import ErrorBoundary from "src/errors/ErrorBoundary"
import { saveError } from "src/helpers/errors"
import useTranslation from "src/hooks/useTranslation"
import { addAttachment, deleteAttachment, listAttachments } from "src/services/attachments.service"
import { logoutCallback } from "src/services/authValidationMiddleware"
import { signModel } from "src/services/general.service"
import useDispatch from "../../store"
import {
  addAttachments,
  deleteAttachment as deleteAttachmentAction,
  updateAttachmentSignatures
} from "../../store/attachments/actions"
import Button from "../Button"
import Dialog from "../Dialog"
import Loading from "../Loading"
import styles from "./style.module.scss"

function isIntegrationKeyValid(
  integrationKey = {
    modelId: "",
    fieldId: ""
  }
) {
  try {
    if (typeof integrationKey.modelId !== "string" || integrationKey.modelId === "") {
      return false
    }

    return !(typeof integrationKey.fieldId !== "string" || integrationKey.fieldId === "")
  } catch {
    return false
  }
}

export function getAttachmentLink({ file }) {
  return `${process.env.webAppUrl}/adjunto/?f=${file?.id}`
}

export function getDownloadLink({ file, accessToken }) {
  return `${process.env.apiUrl}/general/adjunto/${file?.id}/download?token=${accessToken ?? "not_found"}`
}

function Editor(
  {
    type = "inline",
    mode = "full",
    initialValue = "",
    supportsFileAttachments = true,
    onStatefullChange = null,
    onDataChange = null,

    integrationKey = {
      modelId: "",
      fieldId: "",
      modelType: ""
    },

    onLoad = null,
    onInstanceReady = null,

    disabled = false,
    border = true,
    height = 50,
    maxHeight = null,
    toolbarOffset = 0
  },
  ref
) {
  const isBasic = mode === "basic"

  const router = useRouter()
  const dispatch = useDispatch()

  const [isInstanceLoaded, setIsInstanceLoaded] = useState(false)

  const silentUpdateRef = useRef(false)
  const editorInsRef = useRef(null)
  const [changeEvent, setChangeEvent] = useState(null)
  const wrapperRef = useRef(null)
  const requestRef = useRef(0)
  const attachmentsWrapperRef = useRef(null)
  const [attachmentsWrapperWidth, setAttachmentsWrapperWidth] = useState(0)

  const loadTimer = useRef(0)
  const [data, setData] = useState(initialValue)
  const [dragging, setDragging] = useState(false)
  const [dragCounter, setDragCounter] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  const [selectedFile, setSelectedFile] = useState(null)
  const { models } = useSelector((state) => state.attachments)

  const { t } = useTranslation()

  const editorAttachments = isIntegrationKeyValid(integrationKey)
    ? integrationKey.modelId in models && integrationKey.fieldId in models[integrationKey.modelId]
      ? models[integrationKey.modelId][integrationKey.fieldId]
      : null
    : null

  const makeOnLoad = () => {
    if (typeof onLoad === "function") {
      return onLoad
    }

    return null
  }

  useEffect(() => {
    if (!disabled) {
      return
    }

    if (data != initialValue) {
      setData(initialValue)
    }
  }, [disabled, initialValue])

  const onLoadHandler = makeOnLoad()

  const getModelType = () =>
    typeof integrationKey.modelType === "string" && integrationKey.modelType !== ""
      ? integrationKey.modelType
      : undefined

  useImperativeHandle(ref, () => ({
    getEditor() {
      return editorInsRef.current
    },
    focus: () => {
      editorInsRef?.current?.editor?.focus()
    },
    silentUpdateValue: (value = "") => {
      silentUpdateRef.current = true
      setData(value)
    },
    hasAttachments: () => isIntegrationKeyValid(integrationKey) && editorAttachments.length > 0,
    deleteAllAttachments: async () => {
      if (!isIntegrationKeyValid(integrationKey)) {
        return
      }

      await Promise.all(
        editorAttachments.map((attachment) => deleteAttachment(attachment.id, logoutCallback(dispatch, router)))
      )

      batch(() => {
        editorAttachments.forEach((attachment) => {
          dispatch(
            deleteAttachmentAction({
              modelId: integrationKey.modelId,
              fieldId: integrationKey.fieldId,
              fileId: attachment.id
            })
          )
        })
      })
    }
  }))

  useEffect(() => {
    if (!isIntegrationKeyValid(integrationKey)) {
      return
    }

    if (editorAttachments !== null) {
      return
    }

    if (!data) {
      return
    }

    listAttachments(integrationKey.modelId, integrationKey.fieldId, getModelType())
      .then((dataWrapper) => {
        console.log(dataWrapper.data)
        dispatch(
          addAttachments({
            modelId: integrationKey.modelId,
            fieldId: integrationKey.fieldId,
            attachments: dataWrapper.data
          })
        )
      })
      .catch(() => {})
  }, [integrationKey.modelId, integrationKey.fieldId])

  useEffect(() => {
    if (attachmentsWrapperRef.current === null) {
      return
    }

    const updateAttachmentsWrapperWidth = debounce(
      () => {
        setAttachmentsWrapperWidth(attachmentsWrapperRef.current?.offsetWidth ?? 0)
      },
      0,
      {
        leading: true
      }
    )

    updateAttachmentsWrapperWidth()

    const observer = new ResizeObserver(updateAttachmentsWrapperWidth)

    observer.observe(attachmentsWrapperRef.current)

    return () => {
      if (attachmentsWrapperRef.current !== null) {
        observer.unobserve(attachmentsWrapperRef.current)
      }
    }
  }, [attachmentsWrapperRef.current])

  const onDataChangeHandler = (event) => {
    const plainText = event.editor.element.getText()
    const _data = event.editor.getData()

    if (silentUpdateRef.current) {
      silentUpdateRef.current = false
    } else if (onDataChange) {
      onDataChange(
        {
          html: _data,
          plain: plainText
        },
        event
      )
    }
  }

  const handleRequestFileDelete = (file) => {
    setSelectedFile({ ...file })
  }

  const handleCancelRequestFileDelete = () => {
    setSelectedFile(null)
  }

  const handleFileDelete = (file) => {
    deleteAttachment(file.id, logoutCallback(dispatch, router))
      .then(() => {
        dispatch(
          deleteAttachmentAction({
            modelId: integrationKey.modelId,
            fieldId: integrationKey.fieldId,
            fileId: file.id
          })
        )

        const downloadLink = getAttachmentLink({
          file: file,
          isLocalLink: false,
          htmlEncoded: true
        })

        const needle = `<a href="${downloadLink}">Adjunto: ${file.nombre_archivo ?? "-"}</a>`
        const needleStartIdx = data.indexOf(needle)

        if (needleStartIdx === -1) {
          return
        }

        setData(data.substr(0, needleStartIdx) + data.substr(needleStartIdx + needle.length, data.length))

        attachmentsWrapperRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest"
        })
      })
      .catch(() => {})
  }

  const handleFileComplete = (file = { id: "" }) => {
    signModel({
      model: "adjunto",
      modelId: file.id,
      signType: "COMPLETADO"
    }).then((result) => {
      const signatures = result?.data?.firmas ?? null

      if (signatures === null) {
        return
      }

      dispatch(
        updateAttachmentSignatures({
          modelId: integrationKey.modelId,
          fieldId: integrationKey.fieldId,
          fileId: file.id,
          signatures
        })
      )
    })
  }

  const handleFileReview = (file = { id: "" }) => {
    signModel({
      model: "adjunto",
      modelId: file.id,
      signType: "REVISADO"
    }).then((result) => {
      const signatures = result?.data?.firmas ?? null

      if (signatures === null) {
        return
      }

      dispatch(
        updateAttachmentSignatures({
          modelId: integrationKey.modelId,
          fieldId: integrationKey.fieldId,
          fileId: file.id,
          signatures
        })
      )
    })
  }

  const uploadFiles = (files) => {
    if (typeof files === "undefined" || files === null) {
      return
    }

    files = Array.from(files)

    if (files.length < 1) {
      return
    }

    setIsUploading(true)

    const fileUploadPairs = files.map((file) => [
      addAttachment(
        integrationKey.modelId,
        integrationKey.fieldId,
        file,
        file.name,
        getModelType(),
        logoutCallback(dispatch, router)
      ),
      file
    ])

    const uploadGroup = fileUploadPairs.reduce(
      (agg, [fileUploadPromise, file], pairIdx) => {
        agg.files[pairIdx] = file

        agg.promises.push(
          fileUploadPromise
            .then((dataWrapper) => {
              return [true, null, dataWrapper]
            })
            .catch((error) => {
              return [false, error, null]
            })
        )

        return agg
      },
      {
        promises: [],
        files: {}
      }
    )

    return Promise.all(uploadGroup.promises)
      .then((results) => {
        const errorResult = results.find(([ok]) => !ok) ?? null
        let errorMessage = null

        if (errorResult !== null) {
          const error = errorResult[1]

          const errorInfo = {
            typeof: typeof error,
            error: error,
            message: {
              typeof: typeof error.message,
              value: error.message
            },
            response: {
              typeof: typeof error.response,
              value: error.response
            }
          }

          saveError(error, errorInfo, `${process.env.webAppUrl}${router.asPath}`, localStorage.getItem("user"))

          if (error.message === "Network Error") {
            errorMessage =
              "Ha sucedido un error con su conexión de Internet, " + "por favor valide que su conexión sea estable."
          } else if (
            typeof error.response?.data?.message !== "undefined" &&
            typeof error.response?.data?.message !== "string"
          ) {
            const messageObj = error.response.data.message

            const exploredMessages = Object.values(messageObj).reduce((agg, messages) => {
              if (typeof messages === "string") {
                agg.push(messages)
              } else if (Array.isArray(messages)) {
                messages.forEach((_message) => {
                  agg.push(_message)
                })
              } else {
                agg.push(JSON.stringify(messages))
              }

              return agg
            }, [])

            errorMessage = exploredMessages.join(" \n")
          } else {
            errorMessage =
              error.message ||
              "Ha sucedido un error con el servidor, por favor póngase en contacto con un Administrador."
          }
        }

        const successes = results
          .map((result, resultIdx) => {
            const [ok, _, dataWrapper] = result

            if (!ok) {
              return null
            }

            return {
              dataWrapper,
              resultIdx
            }
          })
          .filter((r) => !!r)

        const successesResult = successes.reduce(
          (agg, success, successIdx) => {
            const { dataWrapper, resultIdx } = success
            const file = uploadGroup.files[resultIdx]

            const newFile = merge(dataWrapper.data, {
              peso_archivo: file.size,
              created_at: new Date().toISOString(),
              created_by: ""
            })

            agg.attachmentsMessage += `
          <a href="${getAttachmentLink({
            file: dataWrapper.data
          })}">Adjunto: ${dataWrapper.data?.nombre_archivo ?? "-"}</a>
          ${successIdx < successes.length - 1 ? "<br/>" : ""}
        `

            agg.attachments.push(
              addAttachments({
                modelId: integrationKey.modelId,
                fieldId: integrationKey.fieldId,
                attachments: [newFile]
              })
            )

            return agg
          },
          {
            attachmentsMessage: "",
            attachments: []
          }
        )

        if (successesResult.attachments.length > 0) {
          //
          editorInsRef?.current?.editor?.insertHtml(`<p>${successesResult.attachmentsMessage}</p>`)

          batch(() => {
            successesResult.attachments.forEach((_attachment) => {
              dispatch(_attachment)
            })
          })
        }

        if (errorMessage !== null) {
          throw new Error(errorMessage)
        }
      })
      .finally(() => {
        setIsUploading(false)

        attachmentsWrapperRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest"
        })
      })
  }

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (!isIntegrationKeyValid(integrationKey)) {
      return
    }

    const _files = e.dataTransfer.files

    if (_files && _files.length > 0) {
      uploadFiles(_files).catch((error) => {
        toast.error(error?.message || "No se pudo subir los adjuntos seleccionados.")
      })

      e.dataTransfer.clearData()

      setDragCounter(0)
    }

    setDragging(false)
  }

  const handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (!isIntegrationKeyValid(integrationKey)) {
      return
    }

    setDragCounter(dragCounter + 1)

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true)
    }
  }

  const handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setDragCounter(dragCounter - 1)

    if (dragCounter > 0) {
      return
    }

    setDragging(false)
  }

  const renderFiles = () => {
    if (editorAttachments === null || editorAttachments.length === 0) {
      return null
    }

    const uniqueIds = []

    const unique = editorAttachments.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.id)

      if (!isDuplicate) {
        uniqueIds.push(element.id)

        return true
      }

      return false
    })

    return unique.map((file, idx) => {
      return (
        <EditorAttachment
          disabled={disabled}
          wrapperWidth={attachmentsWrapperWidth}
          file={file}
          key={idx}
          fileIdx={idx}
          handleRequestFileDelete={handleRequestFileDelete}
          handleFileComplete={integrationKey?.withComplete ?? true ? handleFileComplete : false}
          handleFileReview={integrationKey?.withReview ?? true ? handleFileReview : false}
          withSignature={typeof integrationKey?.withSignature === "undefined" ? true : integrationKey?.withSignature}
          className={typeof integrationKey?.className === "undefined" ? null : integrationKey?.className}
          size={integrationKey?.size}
        />
      )
    })
  }

  const wrapperClassName = `${mode === "full" ? styles.wrapperFullMode : styles.wrapper} ${
    styles.editorWrapper
  } formatted`

  const handleOnAttachmentUpload = useCallback(
    ({ attachedFiles }) =>
      uploadFiles(attachedFiles).then(() => ({
        success: true,
        message: attachedFiles.length === 1 ? "Se subió el archivo." : "Se subieron los archivos."
      })),
    [uploadFiles]
  )

  const handleOnAttachmentFailure = useCallback((error) => {
    toast.error(error?.message || "No se pudo subir los adjuntos seleccionados.")
  }, [])

  const handleInstanceReady = ({ editor }) => {
    editorInsRef.current = { editor, element: editor.container.$ }
    setIsInstanceLoaded(true)
    if (typeof onInstanceReady == "function") {
      onInstanceReady()
    }

    if (onStatefullChange) {
      editor.on("change", (ev) => {
        setChangeEvent(ev)
      })
    }
  }

  useEffect(() => {
    if (changeEvent) {
      handleChange(changeEvent)
      setChangeEvent(null)
    }
  }, [changeEvent])

  const handleChange = (event) => {
    const plainText = event.editor.element.getText()
    const _data = event.editor.getData()

    if (silentUpdateRef.current) {
      silentUpdateRef.current = false
    } else if (onStatefullChange) {
      onStatefullChange({ html: _data, plain: plainText }, event)
    }
  }

  const onClickWrapper = function () {
    if (editorInsRef.current !== null && typeof editorInsRef.current.editor !== "undefined") {
      editorInsRef.current.editor.focus()
    }
  }

  const makeEditorConfig = useCallback(() => {
    if (isBasic) {
      return {
        toolbar: [
          {
            name: "basicstyles",
            groups: ["basicstyles", "cleanup"],
            items: ["Bold", "Italic"]
          },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi"],
            items: ["NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "TextColor"]
          }
        ],
        title: false,
        autoParagraph: false,
        enterMode: 2,
        removePlugins: "elementspath",
        extraPlugins:
          "fixed,uploadimage,pastebase64,openlink,attach,justify,colorbutton,pastefromexcel,colordialog,pasolink",
        language: "es",
        openlink_modifier: 0,
        toolbarOffset
      }
    }

    return {
      toolbar: [
        {
          name: "clipboard",
          items: ["Cut", "Copy", "Paste", "-", "Undo", "Redo"]
        },
        {
          name: "links",
          items: isIntegrationKeyValid(integrationKey) ? ["Link", "Attachments"] : ["Link"]
        },
        {
          name: "insert",
          items: ["Table", "SpecialChar"]
        },
        {
          name: "tools",
          items: ["Maximize"]
        },
        {
          name: "basicstyles",
          items: ["Bold", "Italic", "Underline", "-", "RemoveFormat"]
        },
        {
          name: "paragraph",
          items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Outdent",
            "Indent",
            "-",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock",
            "-",
            "TextColor"
          ]
        },
        {
          name: "styles",
          items: ["Styles", "Format"]
        }
      ],
      title: false,
      autoParagraph: false,
      enterMode: 2,
      removePlugins: "elementspath",
      extraPlugins:
        "fixed,uploadimage,pastebase64,openlink,attach,justify,colorbutton,pastefromexcel,colordialog,pasolink",
      language: "es",
      openlink_modifier: 0,
      uploadUrl: "",
      onAttachmentUpload: handleOnAttachmentUpload,
      onAttachmentFailure: handleOnAttachmentFailure,
      toolbarOffset
    }
  }, [isBasic, disabled, handleOnAttachmentUpload, handleOnAttachmentUpload, toolbarOffset])

  const loadChecker = debounce(() => {
    if (editorInsRef === null || editorInsRef.current === null || !("editor" in editorInsRef.current)) {
      loadTimer.current = setTimeout(loadChecker, 100)

      return
    }

    if (typeof editorInsRef.current?.editor === "undefined" || editorInsRef.current?.editor === null) {
      loadTimer.current = setTimeout(loadChecker, 100)

      return
    }

    if (!("instanceReady" in editorInsRef.current.editor)) {
      loadTimer.current = setTimeout(loadChecker, 100)

      return
    }

    if (!editorInsRef.current.editor.instanceReady) {
      loadTimer.current = setTimeout(loadChecker, 100)

      return
    }

    if (onLoadHandler === null) {
      return
    }

    onLoadHandler()
  }, 100)

  useEffect(() => {
    if (CKEditor) {
      CKEditor.disableAutoInline = true
    }

    if (disabled && typeof onLoadHandler === "function") {
      onLoadHandler()
    }
  }, [])

  useEffect(() => {
    if (editorInsRef.current === null) {
      return
    }

    if (mode === "full") {
      editorInsRef.current.element.style.minHeight = "100%"
    } else {
      editorInsRef.current.element.style.overflow = "auto"
    }

    if (loadTimer.current > 0) {
      clearTimeout(loadTimer.current)
    }

    loadTimer.current = setTimeout(loadChecker, 100)

    return () => {
      clearTimeout(loadTimer.current)
    }
  }, [editorInsRef.current])

  return (
    <ErrorBoundary>
      <div
        ref={wrapperRef}
        className={cx(wrapperClassName, "editor-wrapper", "ui segment basic")}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        style={{
          border: border ? "1px solid #d6d6d6" : "none",
          minHeight: height,
          maxHeight: maxHeight ? maxHeight : height + 100,
          overflowY: disabled ? "auto" : null,
          opacity: disabled ? 0.7 : null
        }}
        onClick={onClickWrapper}
      >
        {isUploading ? <Loading /> : null}
        {!isInstanceLoaded && !disabled && onLoadHandler === null ? (
          <div className='ui dimmer inverted active'>
            <div className='ui text loader'>{t("main_ui.components.editor.lb_loading_component")}</div>
          </div>
        ) : null}
        {disabled ? (
          <ErrorBoundary>
            <div dangerouslySetInnerHTML={{ __html: data }}></div>
          </ErrorBoundary>
        ) : (
          <CKEditor
            type={type}
            readOnly={disabled}
            initData={data}
            config={makeEditorConfig()}
            onChange={onDataChangeHandler}
            onInstanceReady={handleInstanceReady}
            editorUrl={"/ckeditor4/ckeditor.js"}
            onBlur={() => {
              if (mode === "full") {
                cancelAnimationFrame(requestRef.current)
              }
            }}
          />
        )}
        {!supportsFileAttachments || editorAttachments === null || editorAttachments.length === 0 ? null : (
          <div
            className={cx(styles.fileAttachmentsWrapper, integrationKey?.className, integrationKey?.size)}
            ref={attachmentsWrapperRef}
          >
            <div className={styles.fileAttachmentsTitle}>Adjuntos:</div>

            {renderFiles()}

            <Dialog isOpen={selectedFile !== null}>
              <Dialog.Header theme='light' buttonClose={false}>
                <h4>
                  <i className='ui icon bell' /> {t("main_ui.components.editor.delete_attachment__title")}
                </h4>
              </Dialog.Header>
              <Dialog.Body theme='light'>
                <span
                  style={{
                    lineHeight: "1.5em",
                    color: "black"
                  }}
                >
                  {t("main_ui.components.editor.delete_attachment__confirmation", {
                    file: selectedFile?.nombre_archivo
                  })}
                </span>
              </Dialog.Body>
              <Dialog.Footer theme='light'>
                <Button
                  text='btn_cancel'
                  name='warning'
                  type='big'
                  handleClick={(event) => {
                    event.preventDefault()

                    handleCancelRequestFileDelete()
                  }}
                />
                <Button
                  text='btn_delete'
                  name='normal'
                  type='big'
                  handleClick={(event) => {
                    event.preventDefault()

                    handleFileDelete({ ...selectedFile })

                    setSelectedFile(null)
                  }}
                />
              </Dialog.Footer>
            </Dialog>
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default forwardRef(Editor)
