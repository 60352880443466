import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import formatFunc from "date-fns/format"
import Link from "next/link"
import { getDownloadLink } from "src/components/Editor"
import styles from "src/components/Editor/style.module.scss"
import Icon from "src/components/Icon"
import Signature from "src/components/Signature"
import Truncate from "src/components/Truncate"
import { getFileExtension, mapFileExtension, readableFileSize } from "src/store/attachments/helpers"

export default function EditorAttachment({
  file = {},
  fileIdx = -1,
  wrapperWidth = 0,
  handleRequestFileDelete = () => {},
  handleFileComplete = () => {},
  handleFileReview = () => {},
  disabled = false,
  withSignature = true,
  size = null,
  className = null
}) {
  const signatures = file.firmas ?? {
    completado: [],
    revisado: []
  }

  const mapSignature = (
    signature = {
      created_at: "",
      created_by: {
        email: "",
        id: "",
        name: "",
        sigla: ""
      }
    }
  ) => {
    const { created_at: _createdAt, created_by: createdBy } = signature
    const createdAt = formatFunc(new Date(_createdAt), "yyyy-MM-dd")

    return {
      label: createdBy.sigla,
      name: createdBy.name,
      date: createdAt
    }
  }

  const completedSignatures = withSignature ? signatures.completado.map(mapSignature) : null
  const reviewedSignatures = withSignature ? signatures.revisado.map(mapSignature) : null

  const iconSize = size === "mini" ? 24 : 48

  const FileOptions = ({ children }) => {
    if (wrapperWidth >= 620) {
      return <>{children}</>
    }

    return (
      <>
        <div className={styles.optionsGroup}>{children.slice(0, 2)}</div>
        <div className={styles.optionsGroup}>{children.slice(2, children.length)}</div>
      </>
    )
  }

  return (
    <div className={cx(styles.file, className, size)} key={`editor-attached-file-${fileIdx}`}>
      <div className={styles.fileIcon}>
        <Icon name={`file-types/${mapFileExtension(getFileExtension(file["nombre_archivo"] ?? ""))}`} size={iconSize} />
      </div>

      <div className={styles.fileDescription}>
        <div className={styles.fileName}>
          <Truncate>{file["nombre_archivo"] ?? "-"}</Truncate>
        </div>
        <div className={wrapperWidth >= 620 ? styles.fileOptions : `${styles.fileOptions} ${styles.lessThan620}`}>
          <FileOptions>
            <div className={styles.fileOption}>
              <span className={styles.fileSizeOption}>{readableFileSize(file["peso_archivo"] ?? 0)}</span>

              <div className={styles.downloadContainerLink}>
                <Link
                  href={getDownloadLink({
                    file,
                    accessToken: localStorage.getItem("token") ?? ""
                  })}
                >
                  <a target='_blank'>
                    <i className='ui icon download fitted' /> <span className={cx(styles.actionLabel)}>Descargar</span>
                  </a>
                </Link>
              </div>
            </div>
            {!disabled && (
              <div className={`${styles.fileOption} ${styles.deleteFileOption}`}>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    handleRequestFileDelete(file, fileIdx)
                  }}
                >
                  <span className={cx(styles.actionLabel)}>Eliminar</span>
                </a>
              </div>
            )}

            {handleFileComplete !== false && (
              <div className={`${styles.fileOption} ${styles.completeFileOption}`}>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    handleFileComplete(file, fileIdx)
                  }}
                >
                  <div className={styles.completeFileOptionIcon}>
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <span className={cx(styles.actionLabel)}>Completar</span>
                </a>
              </div>
            )}

            {handleFileReview !== false && (
              <div className={`${styles.fileOption} ${styles.reviewFileOption}`}>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    handleFileReview(file, fileIdx)
                  }}
                >
                  <div className={styles.reviewFileOptionIcon}>
                    <FontAwesomeIcon icon={faCheck} />
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <span className={cx(styles.actionLabel)}>Revisar</span>
                </a>
              </div>
            )}
            {withSignature && (
              <div className={styles.fileOption}>
                <Signature
                  size='mini'
                  isCompleted={completedSignatures.length > 0}
                  isReviewed={reviewedSignatures.length > 0}
                  completes={completedSignatures}
                  reviews={reviewedSignatures}
                />
              </div>
            )}
          </FileOptions>
        </div>
      </div>
    </div>
  )
}
