import cx from "classnames"
import { useSelector } from "react-redux"
import { Popup } from "semantic-ui-react"
import useTranslation from "src/hooks/useTranslation"
import Icon from "../Icon"
import SigningButtons from "../SigningButtons"
import styles from "./style.module.scss"

export default function Signature(props) {
  const {
    className = null,
    size = "normal",
    isCompleted = false,
    isReviewed = false,
    reviews = [],
    completes = [],
    propsSigningButtons = {},
    labelSize = "normal",
    ...otherProps
  } = props

  const { tree: treeState } = useSelector((state) => state)

  const { t } = useTranslation()

  switch (size) {
    case "normal":
    case "small":
    case "big":
    case "mini":
      break
    default:
      throw new Error(`size cannot be "${size}". The only supported values are 'normal', 'small', 'mini', and 'big'.`)
  }
  switch (labelSize) {
    case "normal":
    case "small":
    case "big":
    case "mini":
      break
    default:
      throw new Error(
        `size cannot be "${labelSize}". The only supported values are 'normal', 'small', 'mini', and 'big'.`
      )
  }

  let iconSize = 0

  switch (size) {
    case "mini":
      iconSize = 18
      break
    case "small":
      iconSize = 24
      break
    case "big":
      iconSize = 48
      break
    case "normal":
    default:
      iconSize = 32
      break
  }

  const completedIconClassName = `${styles.icon} ${styles[size]}${isCompleted ? " " + styles.completed : ""}`
  const reviewedIconClassName = `${styles.icon} ${styles[size]}${isReviewed ? " " + styles.reviewed : ""}`
  const labelClasses = `${styles.label} ${styles[labelSize]}`

  return (
    <div className={cx(styles.signatureWrapper, className)}>
      <div className={styles.signature} {...otherProps}>
        <Popup
          flowing
          hoverable
          className={cx("compact")}
          header={
            <div className={cx(styles.tooltipTitle, "header")}>{t("main_ui.general.lb_signature_completed_by")}:</div>
          }
          trigger={
            <div className={styles.person}>
              <div className={labelClasses}>P</div>

              <div className={completedIconClassName}>
                <Icon size={iconSize} color={isCompleted ? "#0a858d" : "#424242"} name='font-awesome/user_circle' />
              </div>
            </div>
          }
          content={
            <div className={cx(styles.signatureTooltip, "content")} onClick={(e) => e.stopPropagation()}>
              {completes.length === 0 ? (
                <div className={cx(styles.tooltipMessage, styles.empty)}>
                  <i className='ui icon info circle' />
                  {t("main_ui.general.lb_signature_completed__no_records")}
                </div>
              ) : (
                <div className={styles.tooltipItems}>
                  {completes.map((complete, idx) => (
                    <div className={styles.tooltipItem} key={`signature-tooltip-p-${idx}`}>
                      <div className={styles.tooltipItemName}>
                        <span className={`${styles.tooltipItemLabel} ${styles.isComplete}`}>({complete.label})</span>{" "}
                        <span>{complete.name}</span>
                      </div>

                      <div className={styles.tooltipItemExtra}>{complete.date}</div>
                    </div>
                  ))}
                </div>
              )}

              {treeState.configs?.cstm_signature_button_from_tree == 1 && (
                <div className={styles.signingWrapper}>
                  <SigningButtons
                    {...propsSigningButtons}
                    buttons={{ completado: true, revisado: false }}
                    type='small'
                  />
                </div>
              )}
            </div>
          }
        ></Popup>

        <Popup
          flowing
          hoverable
          className={cx("compact")}
          header={
            <div className={cx(styles.tooltipTitle, "header")}>{t("main_ui.general.lb_signature_reviewed_by")}</div>
          }
          trigger={
            <div className={styles.person}>
              <div className={labelClasses}>R</div>

              <div className={reviewedIconClassName}>
                <Icon size={iconSize} color={isReviewed ? "#a8cf45" : "#424242"} name='font-awesome/user_circle' />
              </div>
            </div>
          }
          content={
            <div className={cx(styles.signatureTooltip, "content")} onClick={(e) => e.stopPropagation()}>
              {reviews.length === 0 ? (
                <div className={cx(styles.tooltipMessage, styles.empty)}>
                  <i className='ui icon info circle' />
                  {t("main_ui.general.lb_signature_reviewed__no_records")}
                </div>
              ) : (
                <div className={styles.tooltipItems}>
                  {reviews.map((review, idx) => (
                    <div className={styles.tooltipItem} key={`signature-tooltip-r-${idx}`}>
                      <div className={styles.tooltipItemName}>
                        <span className={`${styles.tooltipItemLabel} ${styles.isReviewed}`}>({review.label})</span>{" "}
                        <span>{review.name}</span>
                      </div>
                      <div className={styles.tooltipItemExtra}>{review.date}</div>
                    </div>
                  ))}
                </div>
              )}

              {treeState.configs?.cstm_signature_button_from_tree == 1 && (
                <div className={styles.signingWrapper}>
                  <SigningButtons
                    {...propsSigningButtons}
                    buttons={{ completado: false, revisado: true }}
                    type='small'
                  />
                </div>
              )}
            </div>
          }
        ></Popup>
      </div>
    </div>
  )
}
