import { cloneDeep } from "lodash"
import { treeItemColumn } from "../constants/treeItemColumn"

export const getUpdatedStatuses = ({ statusesToUpdate, statuses }) => {
  let newStatuses = cloneDeep(statuses)

  newStatuses.forEach((el) => {
    const found = Object.entries(statusesToUpdate).find(([key, data]) => key === el.statusId)

    if (found) {
      const [key, data] = found

      switch (el.statusId) {
        case treeItemColumn.STATUS:
          el.data = {
            ...el.data,
            status: data.status,
            initials: data.sigla,
            name: data.name
          }
          break
        case treeItemColumn.SIGNATURE:
          el.data = {
            ...el.data,
            completed: data.signing.completado.length > 0 ? true : false,
            reviewed: data.signing.revisado.length > 0 ? true : false,
            completers: data.signing.completado,
            reviewers: data.signing.revisado
          }
          break

        case treeItemColumn.DESIGN:
          el.data = data.designControl
          break

        default:
          el.data = { ...el.data, ...data }
          break
      }
    }
  })

  return newStatuses
}
