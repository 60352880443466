import authValidationMiddleware from "./authValidationMiddleware"
import httpClient from "./httpInterceptor"

export const listAttachments = authValidationMiddleware(
  async (modelId = "", fieldId = "", modelType = "auditoria_treeitem", _timestamp = new Date().getTime()) => {
    const url = `/general/adjunto/list/?modelo=${modelType}&modelo_id=${modelId}&campo=${fieldId}&_t=${_timestamp}`

    const response = await httpClient.get(url)

    return response.data
  }
)

export const deleteAttachment = authValidationMiddleware(async (fileId = "") => {
  const url = `/general/adjunto/${fileId}/`

  const response = await httpClient.delete(url)

  return response.data
})

export const addAttachment = authValidationMiddleware(
  async (modelId = "", fieldId = "", file = new Blob(), fileName = "", modelType = "auditoria_treeitem") => {
    const url = `/general/adjunto/`

    const formData = new FormData()

    formData.append("modelo", modelType)
    formData.append("campo", fieldId)
    formData.append("modelo_id", modelId)
    formData.append("archivo", file, fileName)

    const response = await httpClient.post(url, formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    })

    return response.data
  }
)

export const downloadAttachment = authValidationMiddleware(async (fileId = "", token = "") => {
  const url = `/general/adjunto/${fileId}/download?token=${token ?? ""}`

  const response = await httpClient.get(url, {
    responseType: "blob"
  })

  return response
})
