import cx from "classnames"
import styles from "./style.module.scss"

export const strippedString = (text, removeBr = false) => {
  if (typeof text !== "string" || typeof window === "undefined") {
    return text
  }

  if (!removeBr) {
    text = text.replace(/<br(.*\/?)?>/g, "~@ABLB@~")
  }

  const doc = new DOMParser().parseFromString(text, "text/html").body.textContent

  return !removeBr ? doc.replace(/~@ABLB@~/g, "<br/>") || "" : doc
}

export default function SpecialBox({
  text,
  innerHtml = true,
  tag = "div",
  className = null,
  truncate = false,
  removeLinebreak = false,
  maxWidth = null,
  style = {},
  ...attrs
}) {
  const Tag = tag

  return (
    <Tag
      className={cx(
        className,
        styles.specialBox,
        truncate === true && styles.truncate,
        typeof truncate === "number" && isFinite(truncate) && styles.truncateAtLine
      )}
      style={{
        ...style,
        maxWidth,
        WebkitLineClamp: typeof truncate === "number" && isFinite(truncate) ? truncate : null
      }}
      title={truncate ? strippedString(text) : null}
      dangerouslySetInnerHTML={{ __html: innerHtml ? text : strippedString(text, removeLinebreak) }}
      {...attrs}
    />
  )
}
